var site = site || {};
var generic = generic || {};

site.cartConfirm = site.cartConfirm || {};
site.userInfoCookie = site.userInfoCookie || {};

(function ($) {
  Drupal.behaviors.utilityNavV1 = {
    attach: function (context) {
      if (this.attached) {
        return;
      }
      this.attached = true;
      var self = this;
      var $utilDropdownLinks = $('.js-utility-nav__item--dropdown', context);
      var $utilDropdownClose = $('.js-utility-nav__dropdown__close', context);
      var $emailSignupOnload = $('.js-utility-nav__overlay-display-onload', context);
      var $utilCartLink = $('.js-utility-nav-item-cart', context);
      var $cartOverlay = $('.js-cart_overlay', context);
      var $cartCount = $('.js-cart_count', context);
      var isMobile = self.isMobile();
      var position = $(window).scrollTop();
      var cookieName = 'email_signup';
      var hasCookie = $.cookie(cookieName);

      if ($emailSignupOnload.length > 0 && !hasCookie) {
        $emailSignupOnload.not('.active').addClass('active').trigger('changedActiveState');
        $.cookie(cookieName, '1');
      }

      if (!Modernizr.touch) {
        // Hover (desktop)
        var selectBoxFlag = false;

        $utilDropdownLinks
          .on('mouseover', function () {
            var $thismenu = $(this);

            if ($thismenu.has('.selectbox.js-load-on-demand')) {
              $('.selectbox.js-load-on-demand:not(.loadOnDemand-processed)', $thismenu).trigger(
                'selectbox:loadOnDemand'
              );
            }
            $thismenu.not('.active').addClass('active').trigger('changedActiveState');
          })
          .on('mouseout', function (e) {
            // Avoid mouseout on select input suggestion
            // Avoid mouseout on hover input field(Firefox)
            if (e.relatedTarget === null || e.relatedTarget.nodeName.toLowerCase() === 'input') {
              return;
            }
            var $thismenu = $(this);

            $thismenu
              .find('input')
              .once()
              .on('focus', function () {
                $thismenu.addClass('active-hold');
              });
            $thismenu.find('input').on('blur', function () {
              $thismenu.removeClass('active-hold');
            });
            $('.selectBox-options').each(function () {
              if ($(this).is('.selectBox-options-bottom , .selectBox-options-top')) {
                selectBoxFlag = true;

                return false;
              } else {
                selectBoxFlag = false;
              }
            });
            if (selectBoxFlag === false) {
              $(this).filter('.active').removeClass('active').trigger('changedActiveState');
            }
          });

        var $target = $('.js-utility-nav__item--dropdown a.js-utility-nav', context);

        $target.keydown(function (e) {
          if (site.getKeycode(e) === 13) {
            e.preventDefault();
            var $this = $(this);
            var $utilLinks = $this.parents('.js-utility-nav__item--dropdown');

            $this.first().attr('aria-expanded', 'true');
            $utilLinks.trigger('mouseover');
            $('.js-utility-nav__dropdown', context).focus();
          }
          if (e.shiftKey) {
            $(this).parent().removeClass('active');
            $(this).first().attr('aria-expanded', 'false');
          }
        });
      }

      $utilDropdownClose.on('touchstart click', function (event) {
        event.preventDefault();
        var $this = $(this);
        var $utilLinks = $this.closest('.js-utility-nav__item--dropdown');

        $utilLinks.removeClass('active').trigger('changedActiveState');
      });

      // Open cart overlay if PC or redirect if mobile
      self.openCart(isMobile, $utilCartLink, $cartOverlay);

      $(document).on('cart_overlay_launch', function (e, args) {
        var template = 'cart_confirm'; // This class comes from Machine name in inline JS template

        if ($cartOverlay.length === 0) {
          return;
        }

        var trans = args.trans;
        var order = trans.order;
        var cartItems = args.items.concat(order.samples);
        var totals = typeof trans.trans !== 'undefined' ? trans.trans.totals : trans.totals;
        var itemDiscountPrice = Drupal.settings.globals_variables.itemDiscountPrice;
        var templateData = self.getTemplateData(template, trans, cartItems, itemDiscountPrice, $cartOverlay);
        var $subtotalCart = $('.js-sub-num', $cartOverlay);
        var $closeCartConfirm = $('.js-cart-confirm-close', $cartOverlay);
        var $cartOverlayFullView = $('.js-cart-confirm-full-view', $cartOverlay);
        var active_cart_confirm_view;

        $closeCartConfirm.click(function () {
          $cartOverlay.addClass('hidden');
        });

        $(document).trigger('cart_count_update', templateData.items_count);

        // Overlay cart count.
        $('[data-pg-prop="items_count"]', $cartOverlay).html(templateData.items_count);

        // Show/hide the sections depending on item count.
        active_cart_confirm_view = templateData.items_count ? 'js-has-items-in-bag' : 'js-no-items-in-bag';
        $('.' + active_cart_confirm_view, $cartOverlay).removeClass('hidden');

        if (typeof args.newItemProdId !== 'undefined') {
          $('[data-product_id="' + args.newItemProdId + '"][data-sku_id="' + args.newItemSkuId + '"]', $cartOverlay)
            .first()
            .addClass('last-item-added')
            .prependTo('.js-cart-confirm-full-view .js-cart-products');

          // Set add-item view on a 10 second timer.
          setTimeout(function () {
            if ($cartOverlayFullView.hasClass('hidden')) {
              $cartOverlay.addClass('hidden');
            }
          }, 10000);
        }

        if (Drupal.settings.cart_overlay_omit_decimal) {
          $subtotalCart.html(parseInt(totals.PRODUCT_SUM_W_TAX));
        } else {
          $subtotalCart.html(parseFloat(totals.PRODUCT_SUM_W_TAX).toFixed(2));
        }

        // Close confirm overlay on scroll.
        $(window).scroll(
          _.debounce(function () {
            var scroll = $(window).scrollTop();

            // After adding a product to the cart, the page scrolls up
            // If the user scroll down, close cart overlay
            if (scroll > position) {
              $('.js-cart-confirm-close', $cartOverlay).click();
            }
            position = scroll;
          }, 100)
        );
        // All set, show overlay.
        $cartOverlay.removeClass('hidden');
      });

      // Update bag icon count
      $(document).on('cart_count_update', function (e, items_count) {
        var visibility = items_count > 0 ? 'visible' : 'hidden';

        $cartCount.html(items_count);
        $cartCount.css('visibility', visibility);
      });

      $(window).on('load', function () {
        var itemCount = site.userInfoCookie.getValue('item_count');

        $(document).trigger('cart_count_update', itemCount);
      });

      $(window).resize(
        _.debounce(function () {
          var isMobile = self.isMobile();

          self.openCart(isMobile, $utilCartLink, $cartOverlay);
        }, 300)
      );
    },
    getTemplateData: function (template, trans, cartItems, itemDiscountPrice, $cartOverlay) {
      var templateData = {
        items: [],
        show_discount_price: itemDiscountPrice
      };
      var cartItem;

      // Normalize key names in cartItem object. 'prod.PROD_RGN_NAME' --> 'prod_PROD_RGN_NAME'
      for (var i = 0, len = cartItems.length; i < len; i++) {
        cartItem = cartItems[i];
        templateData.items[i] = {};
        for (var key in cartItem) {
          if ((cartItem.hasOwnProperty(key) && key !== 'prod.SUB_LINE') || Drupal.settings.use_sub_line) {
            templateData.items[i][key.replace(/\./, '_')] = cartItem[key];
          }
        }
      }

      templateData.formattedSubtotal = trans.formattedSubtotal;
      templateData.items_count = trans.items_count;

      // Get HTML Template.
      var conf_html = site.template.get({
        name: template,
        data: templateData
      });

      $cartOverlay.html(conf_html);

      return templateData;
    },
    openCart: function (isMobile, $utilCartLink, $cartOverlay) {
      if (isMobile) {
        $utilCartLink.click(function (event) {
          if (!$(event.target).closest('.close').length) {
            window.location = '/checkout/viewcart.tmpl';
          }
        });
      } else {
        $utilCartLink
          .mouseover(
            _.debounce(function (event) {
              event.preventDefault();
              if ($cartOverlay.html() === '') {
                $(document).trigger('cart_confirm');
              } else if ($cartOverlay.hasClass('hidden')) {
                $cartOverlay.removeClass('hidden');
              }
            }, 300)
          )
          .mouseout(
            _.debounce(function () {
              $cartOverlay.addClass('hidden');
            }, 300)
          );
      }
    },
    isMobile: function () {
      var bps = Unison.fetch.all();
      var currentWidth = $(window).width();
      var isMobileWidth = parseInt(currentWidth, 10) <= parseInt(bps.medium, 10);

      return isMobileWidth;
    }
  };
})(jQuery);
